<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="fees"
          dense
          hide-default-footer
          :class="{ 'elevation-1': !disabled}"
          :disabled="disabled"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="color3" small text icon @click.stop="select(item)" v-if="!disabled">
              <v-icon>fas fa-pencil</v-icon>
            </v-btn>
            <v-btn color="error" small text icon @click.stop="preDelete(item)" v-if="!disabled">
              <v-icon>fas fa-trash</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.feeDisplay2`]="{ item }">
            {{item.feeDisplay | usDollars}}
          </template>
        </v-data-table>
      </v-col>
      <v-expand-transition>
        <v-col cols="12" v-if="!disabled" class="text-center">
          <v-btn color="color3" small text @click="addNew">Add New Cart Fee</v-btn>
        </v-col>
      </v-expand-transition>
    </v-row>
    <vbl-confirm-dialog
      :ask="deleteConfirm"
      :subText="`Remove this cart fee from the ${tournament.eventType.toLowerCase()}?`"
      @confirm="deleteMe"
      @deny="deleteConfirm = false"
    ></vbl-confirm-dialog>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <wizard
        :cartFee="selectedFee"
        :startInEdit="true"
        :active="dialog"
        @cancel-click="dialog=false"
        @save-click="saveMe"
        :tournament="tournament"
      ></wizard>
    </v-dialog>
  </v-container>
</template>

<script>
import CartFee from '@/classes/CartFee'
const Wizard = () => import('./Wizard')

export default {
  props: ['tournament', 'divisionId', 'disabled'],
  data () {
    return {
      headers: [
        { text: 'Label', value: 'label' },
        { text: 'Amount', value: 'feeDisplay' },
        { text: '', value: 'actions', align: 'end' }
      ],
      dialog: false,
      selectedFee: null,
      deleteConfirm: false
    }
  },
  computed: {
    jProps () {
      return this.tournament.jProps
    },
    fees () {
      return this.tournament.getCartFees(this.divisionId).map(m => new CartFee(m))
    }
  },
  methods: {
    select (fee) {
      this.selectedFee = new CartFee(fee)
      this.dialog = true
    },
    addNew () {
      this.selectedFee = null
      this.dialog = true
    },
    saveMe (dto) {
      console.log('here')
      if (this.selectedFee) {
        this.tournament.updateCartFee(this.selectedFee.dto, dto)
      } else {
        this.tournament.addCartFee(dto)
      }
      this.dialog = false
    },
    preDelete (item) {
      this.selectedFee = item
      this.deleteConfirm = true
    },
    deleteMe () {
      this.tournament.deleteCartFee(this.selectedFee)
      this.selectedFee = null
      this.deleteConfirm = false
    }
  },
  components: {
    Wizard
  }
}
</script>
